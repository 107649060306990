:root {
  --black: hsl(0, 0%, 7%);
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body,
html {
  font-family: 'Poppins', sans-serif;
}

.nav__link-wrapper a {
  color: #fff;
  text-decoration: none;
  padding: 0 20px;
}

.generate__password {
  background: #daad27;
  color: #fff;
  padding: 2px 10px;
  cursor: pointer;
  border: none;
}

.disable {
  background: rgb(224, 224, 224);
  color: rgba(0, 0, 0, 0.356);
  cursor: not-allowed;
}
