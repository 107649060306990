.page {
  .base {
    padding: 20px;

    .header {
      padding: 0 0 10px;

      .controls {
        width: 100%;
        display: flex;
        justify-content: space-between;

        .buttons {
          justify-content: flex-end;
          display: flex;
          width: 100%;
        }
      }
    }
  }
}
