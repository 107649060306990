@import '../../variables/color.scss';

.sidenav {
  height: 100%;

  .logo {
    width: 100%;
    height: 100%;
    text-align: center;
  }

  .item {
    cursor: pointer;

    &.-active {
      background-color: $light-goldenrod-yellow;
    }

    .icon {
      min-width: 30px;
      color: $christi;

      &.-subitem {
        margin-left: 20px;
      }
    }
  }
}
