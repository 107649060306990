@import '../../variables/color.scss';

.form {
  padding: 20px 80px 0 80px;

  &.-mobile {
    padding-left: 20px;
    padding-right: 20px;
  }

  &.-dialog {
    padding-left: 0;
    padding-right: 0;
  }

  .label {
    margin: 15px 0 8px;
  }

  .action {
    margin: 20px 0;
    position: -webkit-sticky; /* Safari */
    position: sticky;
    bottom: 0;
    text-align: right;
    padding-bottom: 10px;

    &.-public {
      text-align: left;
    }

    &.-unsticky {
      text-align: left;
      position: unset;
      bottom: unset;
      padding-bottom: 10px;
    }

    &.-send-message {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
  }

  .file-details {
    color: $grey;
    padding: 0;
    font-size: 10px;

    &.-error {
      color: $red;
    }

    .remove {
      margin-left: 5px;
      color: $red;
      text-decoration: underline;
      cursor: pointer;
    }
  }
}

.page {
  .details {
    flex-direction: column;
    .id {
      font-weight: bold;
    }
  }
}

.payment {
  margin-top: 20px;
  width: 100%;
  margin-bottom: 20px;

  .connect {
    flex-direction: column;
    text-align: center;

    .stripe {
      display: block;
      margin: 0 auto;
    }
  }
}
