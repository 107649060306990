@import '../variables/color.scss';

.chat {
  padding-left: 20px;
  padding-right: 20px;
  .avatar {
    width: 40px;
    height: 40px;
    border-radius: 100%;
    margin-right: 8px;
  }

  .message {
    width: 60%;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    &.-own {
      justify-content: flex-end;
    }

    .content {
      width: fit-content;
      max-width: 100%;
      background: $other-message-bg;
      border-radius: 18px;
      display: flex;
      flex-direction: column;
      padding: 0 12px;

      &.-own {
        background: $christi;
      }
    }
  }

  .attachment {
    &.-image {
      width: 60px;
      display: flex;
      align-items: center;
      justify-content: flex-start;

      &.-own {
        justify-content: flex-end;
      }

      .image-content {
        border-radius: 24px;
      }
    }

    &.-file {
      width: fit-content;
      max-width: 100%;
      background: $other-message-bg;
      border-radius: 18px;
      display: flex;
      flex-direction: column;
      padding: 0 12px;
      font-size: 12px;

      &.-own {
        background: $own-message-bg;
      }
    }
  }
}
