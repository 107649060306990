.base {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
  justify-content: center;

  .header {
    margin-bottom: 0;
  }

  .title {
    margin-top: 10px;
  }

  .date {
    font-size: 12px;
    text-align: center;
    margin-bottom: 15px;
    margin-top: 0;
  }
}

.css-15vgec8 {
  width: 800px;
}
