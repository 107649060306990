.chats {
  &.-mobile {
    padding: 20px;
  }

  .base {
    .header {
      padding: 0 0 10px;

      .controls {
        padding-left: 20px;
        padding-right: 20px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }
  }
}
