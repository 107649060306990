.no-content {
  .base {
    padding: 20px;
    flex-direction: column;
    text-align: center;

    &.-restricted {
      flex-direction: row;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0;

      &.-mobile {
        flex-direction: column-reverse;
      }
    }

    .image {
      width: 390px;
      height: 390px;

      &.-mobile {
        width: 300px;
        height: 300px;
        margin-top: 20px;
      }

      &.-small {
        width: 250px;
        height: 250px;
      }
    }

    .text {
      flex-direction: column;
    }
  }

  .button {
    margin-top: 10px;
  }
}
