.layout {
  width: 100%;
  display: flex;
  justify-content: center;

  .outlet {
    width: 450px;
    margin: 50px 0;
  }
}
