.modal {
  .base {
    flex-direction: column;
    width: 300px;

    .label {
      font-weight: bold;

      &.-center {
        text-align: center;
      }
    }
  }
}
