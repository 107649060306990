.users {
  .base {
    padding: 20px;

    .header {
      padding: 0 0 10px;

      .controls {
        display: flex;
        justify-content: space-between;
      }
    }
  }
}
