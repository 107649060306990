.report {
  .base {
    padding: 20px;
  }

  .header {
    padding: 0 0 10px;

    .controls {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-bottom: 20px;
    }
  }
}
