@import '../../variables/color.scss';

.table {
  .header {
    background-color: $christi;
    color: white;
  }

  .name {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;

    &.-break {
      white-space: normal !important;
      word-wrap: break-word !important;
    }

    &.-processing {
      color: blue;
      font-weight: bold;
    }

    &.-error {
      color: red;
      font-weight: bold;
    }

    &.-bold {
      font-weight: bold;
    }

    &.-red {
      color: red;
    }

    &.-green {
      color: green;
    }
  }

  .image {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;

    .label {
      margin-left: 5px;
    }
  }

  .invite {
    background: $caduceus-gold;
    padding: 2px 15px;
    font-size: 12px;
    border-radius: 4px;
    cursor: pointer;

    &.-disabled {
      background: lightgray;
      color: white;
      cursor: unset;
    }
  }

  .chat {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    cursor: pointer;

    &.-desktop {
      flex-direction: unset;
      align-items: center;
    }

    &.-new-message {
      font-weight: bold;
    }

    .user {
      font-size: 12px;

      &.-icon {
        font-size: inherit;
        margin-bottom: 5px;
        margin-left: 5px;
      }

      .icon {
        margin-bottom: 5px;
        margin-left: 5px;
      }
    }

    .status {
      font-size: 12px;
      margin-left: 20px;
    }
  }

  .duration {
    &.-new-message {
      font-weight: bold;
    }
  }
}
