@import '../../variables/color.scss';

.form {
  .menu {
    margin: 10px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .links {
      justify-content: space-between;
      display: flex;
      align-items: center;
      width: 100%;

      .link {
        display: flex;

        .text {
          color: $red;
        }
      }
    }
  }

  .action {
    margin: 20px 0;

    .buttons {
      display: flex;
      justify-content: space-between;
    }
  }
}

.join {
  margin-top: 20px;
  padding: 20px;
}
