// Primary Color
$primary: #1976d2;

// CSS Colors
$red: red;
$black: #000000;
$white: #ffffff;
$grey: grey;
$orochimaru: #d9d9d933;
$light-red: #ececec;

// Chat Dialog
$own-message-bg: #0084ff;
$other-message-bg: #e8e8e0;
$dark-gray: #484848;

$caduceus-gold: #fff068;

// Sidebar
$light-goldenrod-yellow: #e6ffd4;
$christi: #096f4d;
$light-christi: #3a8b70;
