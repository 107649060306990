.pages {
  display: flex;
  flex-direction: column;
  align-items: center;

  &.-single {
    width: 300px;
  }

  .search {
    justify-content: flex-start;
    display: flex;
    width: 100%;
    margin-bottom: 20px;
  }

  .no-content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
